@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';
@import '../../styles/shared.scss';

.hideForSmall {
  @include breakpoint('max-width', $breakpoint-medium) {
    display: none !important;
  }
}

// .banner {
//   border-bottom: $border-width solid $black;
//   font-family: $font-family--titles;
//   // padding: calc($padding / 2);
//   // background-color: $black;

//   .article {
//     display: flex;
//     align-items: stretch;
//     justify-content: space-between;

//     @include breakpoint('max-width', $breakpoint-medium) {
//       flex-direction: column;
//     }

//     p {
//       line-height: 1;
//       font-weight: $font-weight--bold
//     }
//   }

//   &Time,
//   &Location {
//     @include breakpoint('max-width', $breakpoint-medium) {
//       width: 100%;
//       justify-content: center;
//       text-align: center
//     }
//   }

//   &Time {
//     display: flex;
//     align-items: center;

//     @include breakpoint('max-width', $breakpoint-medium) {
//       display: flex;
//       justify-content: space-around;
//     }

//   }

//   &TimeOpen {
//     background-color: $black;
//     color: $white;
//     padding: calc($padding / 2);
//     mix-blend-mode: multiply;
//     display: inline-flex;
//     align-items: center;
//     white-space: nowrap;
//     font-weight: $font-weight--bold;

//     @include breakpoint('max-width', $breakpoint-medium) {
//       background-color: transparent;
//       color: $black;
//     }

//     @include breakpoint('min-width', $breakpoint-medium) {
//       margin-left: calc($padding / 4);
//     }

//     svg {
//       width: 18px;
//       height: 18px;
//       fill: $white;
//       margin-right: calc($padding / 4);
//     }
//   }

//   &Location {
//     background-color: $black;
//     color: $white;
//     padding: calc($padding / 2);
//     mix-blend-mode: multiply;
//     display: inline-flex;
//     align-items: center;
//     white-space: nowrap;
//     margin-right: calc($padding / 4);

//     svg {
//       width: 18px;
//       height: 18px;
//       fill: $white;
//       margin-right: calc($padding / 4);
//     }
//   }
// }

.spacer {
  width: 2rem;
}

.outer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  // border: $border-width solid $black;
  border-top: calc($border-width / 2) solid $black;
  border-bottom: calc($border-width / 2) solid $black;

  @include breakpoint('max-width', $breakpoint-medium) {
    overflow: hidden;
  }

  .article {
    @include breakpoint('min-width', $breakpoint-medium) {
      width: calc(100% / 2);
    }
  }

  &Map {
    flex-wrap: nowrap;
    align-items: stretch;

    @include breakpoint('max-width', $breakpoint-medium) {
      flex-direction: column;
    }

    .content {
      display: flex;
      align-items: center;
    }
  }
}

.intro {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  min-height: 50vh;

  svg {
    fill: $black;
    max-width: 420.69px;
    width: 80%;
    // mix-blend-mode: overlay;
  }

  // iframe {
  //   width: 200%;
  //   height: 102%;
  //   @include center();
  //   pointer-events: none;
  // }

  h2,
  p {
    text-wrap: balance;
    max-width: 720px;
    margin: auto;
  }

  .content {
    height: auto;

    @include breakpoint('max-width', $breakpoint-medium) {
      text-align: center;
    }
  }

  .article {
    align-items: center;
  }
}

// .youtube {
//   position: relative;
//   height: 100%;
//   object-fit: cover;
//   overflow: hidden;
//   // filter: grayscale(100%) sepia(90%);
//   @include transition(all 0.666s ease-in-out);

//   &:hover,
//   &:focus {
//     filter: none;
//   }
// }

.news {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  border-top: $border-width solid $black;

  @include breakpoint('max-width', $breakpoint-medium) {
    overflow: hidden;
  }

  .article {
    align-items: flex-start;

    @include breakpoint('min-width', $breakpoint-medium) {
      width: calc(100% / 3);
    }

    img {
      width: 100%;
      aspect-ratio: 2/1;
      object-fit: cover;
      border: calc($border-width / 2) solid $black;
      // filter: grayscale(100%) sepia(90%);
      @include transition(all 0.666s ease-in-out);

      &:hover,
      &:focus {
        filter: none;
      }
    }
  }
}

.logo {
  width: 300px;

  fill: $white;
}

.article {
  display: flex;
  align-items: stretch;
  // display: block;
  // min-height: 0;
  // min-width: 0;
  width: 100%;
  // height: 100%;
  // border-radius: $border-radius;
  // text-decoration: none;
  // transition: $animation-card;
  box-shadow: inset 0 0 6px -2px rgba($black, 0.5);

  &Padding {
    padding: $padding;
  }

  &BorderRight {
    @include breakpoint('min-width', $breakpoint-medium) {
      border-right: $border-width solid $black;
    }
  }

  &BorderLeft {
    @include breakpoint('min-width', $breakpoint-medium) {
      border-left: $border-width solid $black;
    }
  }

  a {
    display: block;
    width: 100%;
    padding: $foundation;
    color: white;
    text-decoration: none;
  }

  img {
    max-width: 100%;
  }

  h2 {
    text-wrap: balance;
  }

  // &:active {
  //   outline: none;
  //   opacity: 0.33 !important;
  //   @include transform(translateY(2px));
  // }
}

.image {
  // background-color: $grey;
  position: relative;
  height: auto;
  width: 100%;
  overflow: hidden;
  border-radius: calc($border-radius / 2);
  aspect-ratio: $sixteen-nine;

  @include breakpoint('max-width', $breakpoint-large) {
    margin-bottom: calc($padding / 2);
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    min-width: 170px;
    max-width: 170px;
    aspect-ratio: $four-three;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  width: 100%;
  height: 100%;
  text-wrap: balance;

  &Video {
    padding: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &Inner {
    padding: $title-padding 0 calc($padding / 4) 0;

    &Centered {
      text-align: center;
    }

    &Intro {
      padding: 15vh 0;
    }

    &BW {
      filter: grayscale(100%) sepia(90%);
      mix-blend-mode: multiply;

      img {
        max-width: 250px;
        max-height: 200px;
      }
    }

    &Media {
      padding: 0;
      aspect-ratio: 1;
      overflow: hidden;
      height: 100%;

      @include breakpoint('max-width', $breakpoint-medium) {
        border-bottom: $border-width solid $black;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    // &MediaVideo {
    //   padding: 0;
    //   aspect-ratio: $sixteen-nine;
    //   overflow: hidden;
    //   height: 100%;
    //   border-bottom: $border-width solid $black;

    //   @include breakpoint('min-width', $breakpoint-medium) {
    //     aspect-ratio: 1;
    //     border-bottom: 0;
    //   }

    //   img {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    //   }
    // }
  }
}

.title {
  margin: 0;
  line-height: $line-height--title;
  @include line-clamp(3);
}

.description {
  font-size: 0.75rem;
  line-height: $line-height--small;
  opacity: $opacity--half;
  @include transition($animation);

  p {
    margin: 0;
    @include line-clamp(2);
  }
}

.icon {
  width: 20px;
  margin-right: calc($padding / 4);
  fill: $white;
}

.marquee {
  pointer-events: none;
}

.marqueeContainer {
  padding: 0;
  // width: fit-content;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-top: $border-width solid $black;
  border-bottom: calc($border-width / 2) solid $black;

  h2 {
    display: flex;
    align-items: center;
    margin: 0;
    white-space: nowrap;
    padding: $padding 0;
    // height: 1rem;
    font-weight: 900;
    line-height: $line-height--small;
    font-family: $font-family--titles;
    mix-blend-mode: multiply;

    img {
      max-width: 72px;
      margin: 0 $padding;
      filter: grayscale(100%) sepia(90%);
    }
  }
}

.flipped {
  transform: rotateY(180deg);
}

.header {
  padding: calc($padding / 2);
  width: fit-content;
  position: relative;
  overflow-x: hidden;
  // height: 1rem;
  width: 100%;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-top: $border-width solid $black;

  @include breakpoint('min-width', $breakpoint-medium) {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h2 {
    display: flex;
    align-items: center;
    margin: 0;
    white-space: nowrap;
    padding: $padding 0;
    // height: 1rem;
    font-weight: 900;
    line-height: $line-height--small;
    font-family: $font-family--titles;
    mix-blend-mode: multiply;

    @include breakpoint('max-width', $breakpoint-medium) {
      justify-content: center;
      font-size: 1.6rem;
      padding: 0;
    }

    img {
      max-width: 72px;
      margin: 0 $padding;
      filter: grayscale(100%) sepia(90%);
    }
  }
}

.footer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  justify-content: space-between;
  border-top: calc($border-width / 2) solid $black;
  text-align: center;
  // min-height: 100vh;

  @include breakpoint('max-width', $breakpoint-medium) {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    overflow: hidden;
  }

  svg {
    fill: $black;
    max-width: 420.69px;
    width: 100%;
    // mix-blend-mode: overlay;
  }

  iframe {
    width: 200%;
    height: 100%;
    border: 0;
    @include center();
    pointer-events: none;
  }

  p {
    font-family: $font-family--titles;
    font-size: 1.6rem;
    line-height: 1.125;
    text-wrap: balance;
    margin-bottom: calc($padding / 2);
    text-wrap: balance;
  }

  .version {
    background-color: $black;
    color: $white;
    padding: calc($padding / 4) calc($padding / 2);
    mix-blend-mode: multiply;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    font-family: $font-family--titles;
    font-size: 0.8rem;

    svg {
      width: 18px;
      height: 18px;
      fill: $white;
      margin-right: calc($padding / 4);
    }

    strong {
      font-weight: $font-weight--bold;
      margin-left: calc($padding / 4);
    }
  }

  .content {
    height: auto;

    @include breakpoint('max-width', $breakpoint-medium) {
      text-align: center;
    }
  }

  .article {
    align-items: center;
    justify-content: center;

    @include breakpoint('min-width', $breakpoint-medium) {
      padding: calc($padding * 2);
    }
  }
}