@import "../../styles/globals.scss";
@import "../../styles/mixins.scss";
@import "../../styles/shared.scss";

.outer {
  width: calc(100% + 4px);
  margin-left: -2px;
  margin-right: -2px;
}

.article {
  align-items: flex-start;
  padding: $padding;
  border-left: calc($border-width / 2) solid $black;
  border-right: calc($border-width / 2) solid $black;
  border-bottom: $border-width solid $black;

  &:first-child {
    border-left: 0;
  }

  &:last-child {
    border-right: 0;
  }

  a {
    color: $black;
    text-decoration: none;
  }

  @include breakpoint("min-width", $breakpoint-medium) {
    width: 50%;
  }

  @include breakpoint("min-width", $breakpoint-large) {
    flex: 1;
    border-bottom: 0;
  }
}

.image {
  font-size: 0;
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
  border: calc($border-width / 2) solid $black;
  // filter: grayscale(100%) sepia(90%);
  @include transition(all 0.666s ease-in-out);

  &:hover,
  &:focus {
    filter: none;
  }
}

.content {
  width: 100%;

  &Inner {
    padding: $title-padding 0 calc($padding / 4) 0;

    &Media {
      padding: 0;

      image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.title {
  margin: 0;
  padding-bottom: $title-padding;
  line-height: $line-height--title;
  text-wrap: balance;
  @include line-clamp(3);

  @include breakpoint("min-width", $breakpoint-large) {
    @include line-clamp(2);
  }
}

.description {
  font-size: 0.9rem;
  line-height: $line-height--small;
  opacity: 0.75;
  text-wrap: balance;

  p {
    margin: 0;
    @include line-clamp(2);
  }
}

.loader {
  width: 90px;
  opacity: 0.75;
  box-shadow: none !important;
  @include center();
}
