@import 'globals.scss';
@import 'mixins.scss';

html {
  margin: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::selection {
  background: transparent;
}

html,
body {
  font-family: $font-family;
  font-weight: $font-weight--normal;
  font-size: $font-size--body;
  font-optical-sizing: auto;
  // cursor: none;
}

body {
  background-color: $biege;
  color: $black;
  // touch-action: none;
}

h1,
h2,
h3,
h4 {
  font-family: $font-family--titles;
  font-weight: $font-weight--bold;
  margin: calc($padding / 2) 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.125);

  span {
    display: block;
    font-weight: $font-weight--light;
    opacity: 0.777;
  }
}

h1 {
  font-size: 2.75rem;
  line-height: $line-height--title;
}

h2 {
  font-size: 2.25rem;
  line-height: $line-height--title;
}

h3 {
  font-size: 1.75rem;
  line-height: $line-height--title;
}

h4 {
  font-size: 1.25rem;
  line-height: $line-height--title;
}

p {
  margin: 0;
  // line-height: $line-height--small;
}

.main {
  position: relative;
}

.background {
  background-size: cover;
  background-position: center;
  position: fixed;
  top: -5%;
  left: -5%;
  right: -5%;
  bottom: -5%;
  opacity: 0.125;
  z-index: -1;
}

.container {
  background-color: rgba(255, 255, 255, 0.125);
  width: 100%;
  height: 100%;
  max-width: $max-width;
  margin: auto;
  border-left: $border-width solid $black;
  border-right: $border-width solid $black;
  // padding: $padding;

  // @include breakpoint('min-width', $breakpoint-medium) {
  //   padding: $padding * 2;
  // }
}

.track {
  white-space: nowrap;
}

.header {
  // position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  // mix-blend-mode: multiply;
  mix-blend-mode: multiply;
  @include transition(all 0.666s ease-in-out);
}

.nav {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  // background-color: $biege;
  background-color: $black;
  color: $white;
  // backdrop-filter: blur(25px);
  border-bottom: calc($border-width / 2) solid $black;
  width: 100%;
  // margin-left: calc(-1 * $border-width);
  // margin-right: calc(-1 * $border-width);
  font-family: $font-family--titles;
  text-transform: capitalize;
  overflow: hidden;
  scrollbar-width: none;
  @include transition(all 0.666s ease-in-out);
}

.active {
  background-color: $black;
  color: $white;

  span {
    color: $white !important;
  }
}

.track {
  white-space: nowrap;
}

.icons {
  display: flex;
  position: relative;
  padding: calc($padding / 2);
  width: 100%;
  border: calc($border-width / 2) solid darken($black, 5%);
  align-items: center;
  // mix-blend-mode: multiply;
  text-align: center;
  cursor: pointer;
  box-shadow: inset 0 0 6px -2px rgba($black, 0.5);
  @include transition(all 0.333s ease-in-out);

  @include breakpoint('max-width', $breakpoint-medium) {
    flex-direction: column;
    justify-content: space-between;
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    svg,
    img {
      margin-right: calc($padding / 2);
    }
  }

  svg,
  img {
    position: relative;
    // max-width: 36px;
    max-height: 50px;
    max-width: 50px;
    transition: $animation;
    filter: sepia(90%) saturate(100%);
  }

  span {
    color: $white;
  }

  &:hover,
  &:focus {
    background-color: rgba($white, 0.25);

    span {
      color: $white;
    }

    @include breakpoint('min-width', $breakpoint-medium) {
      svg {
        @include transform(scale(1.333) translateY(-25%));
      }
    }
  }

  @include breakpoint('min-width', $breakpoint-large) {
    display: inherit;
    margin: 0;
  }

  &Hide {
    @include breakpoint('max-width', $breakpoint-large) {
      display: none;
    }
  }

  &Show {
    @include breakpoint('min-width', $breakpoint-large) {
      display: none;
    }
  }

  &Language {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      max-width: 36px;
      max-height: 36px;
    }

    &Flag {
      @include breakpoint('max-width', $breakpoint-medium) {
        display: none;
      }
    }

    &Inner {
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint('max-width', $breakpoint-medium) {
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
  }

  &Active {
    background-color: rgba($black, 0.75);

    span {
      color: $white;
    }

    @include breakpoint('min-width', $breakpoint-medium) {
      svg {
        @include transform(scale(1.333) translateY(-25%));
      }
    }
  }
}

.active {
  opacity: $opacity-end;
  overflow: visible;

  span {
    opacity: $opacity-end;
  }
}

.tooltip {
  font-size: 1.25rem;
  color: $white;
  transition: $animation;

  @include breakpoint('max-width', $breakpoint-medium) {
    font-size: 1rem;
    display: block;
  }
}

.version {
  position: fixed;
  display: flex;
  align-items: center;
  font-size: .5rem;
  line-height: 1;
  padding-bottom: calc($padding / 4);
  z-index: 3;
  font-weight: $font-weight--bold;
  bottom: 0;
  text-transform: uppercase;

  @include breakpoint('max-width', $breakpoint-medium) {
    left: calc($padding / 4);
  }

  @supports (-webkit-touch-callout: none) {
    left: $padding * 0.975;
  }

  @include breakpoint('min-width', $breakpoint-medium) {
    font-size: .6rem;
    right: 0;
  }

  @include breakpoint('min-width', $breakpoint-large) {
    padding-bottom: calc($padding / 2);
    padding-right: calc($padding / 4);
  }

  svg {
    width: 13px;
    margin-right: $title-padding;
    fill: $black;
  }

  &Inner {
    margin: 0 $title-padding;
    display: flex;
    align-items: center;
    opacity: .25;
    @include transition($animation);

    @include breakpoint('min-width', $breakpoint-medium) {
      margin: 0 calc($padding / 2);
    }

    &:hover,
    &:focus {
      opacity: $opacity-end;
    }

    &HideForMobile {
      @include breakpoint('max-width', $breakpoint-medium) {
        display: none;
      }
    }
  }
}

.divider {
  height: 100%;
  background: $black;
  display: flex;
  height: calc($nav-height / 3);
  margin: 0 calc($padding / 2);
  width: 1px;
  opacity: 0.125;
  min-width: 1px;
}