// .tags {
//   display: flex;
//   align-items: center;
//   overflow-y: scroll;
//   scrollbar-width: none;
//   -webkit-mask-image: linear-gradient(to right, black calc(100% - 6px), transparent 100%);
//   mask-image: linear-gradient(to right, black calc(100% - 6px), transparent 100%);
//   transition: $animation;

//   .tag {
//     margin-right: calc($padding / 2);
//     text-transform: lowercase;
//     font-size: 0.75rem;
//     padding: $span-padding;
//     border-radius: calc($border-radius / 2);
//     background-color: $grey;
//     opacity: $opacity--hidden;
//     line-height: 1;
//     white-space: nowrap;
//     text-transform: ellipsis;
//     // overflow: hidden;
//     transition: $animation;
//     cursor: alias;

//     &:hover,
//     &:focus {
//       background-color: $grey--hover;
//     }
//   }

//   &Shown {
//     span {
//       opacity: $opacity--shown;
//     }
//   }
// }

// .channel {
//   font-size: 0.75rem;
//   padding: $span-padding;
//   border-radius: calc($border-radius / 2);
//   background-color: $grey;
//   line-height: 1;
//   min-width: 60px;
//   text-align: center;
// }

.date {
  position: absolute;
  top: calc($padding / 4);
  left: calc($padding / 4);
  font-size: 0.65rem;
  font-weight: $font-weight--bold;
  color: $white;
  background-color: $black;
  border-radius: calc($border-radius / 4);
  padding: $span-padding;
  opacity: $opacity--hidden;
  text-transform: lowercase;
  transition: $animation;
}

// .meta {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   font-size: 0.85rem;
//   overflow: hidden;

//   span {
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }

//   img {
//     background-color: $grey;
//     min-width: 26px;
//     max-width: 26px;
//     min-height: 26px;
//     border-radius: 26px;
//     overflow: hidden;
//     font-size: 0;
//     aspect-ratio: $one-one;
//     margin-right: calc($padding / 4);
//   }

//   &Inner {
//     display: flex;
//     align-items: center;
//     overflow: hidden;
//     font-size: 0.75rem;

//     &:hover,
//     &:focus {
//       span {
//         background-color: $grey--hover;
//         @include box-shadow($shadow-edges);
//       }
//     }

//     span {
//       transition: $animation;
//       padding: $span-padding;
//       border-radius: calc($border-radius / 2);
//       background-color: $grey;
//       line-height: 1;
//     }
//   }
// }

// .duration {
//   font-size: 0.75rem;
//   opacity: $opacity--half;
//   transition: $animation;
//   display: flex;
//   align-items: center;

//   span {
//     margin-left: calc($padding / 2);
//     display: flex;
//     align-items: center;
//     line-height: 1;

//     svg {
//       min-width: 16px;
//       width: 16px;
//       fill: $grey--hover;
//       margin-right: $title-padding;
//     }
//   }
// }

.close {
  position: fixed;
  top: calc($padding / 2);
  right: calc($padding / 2);
  z-index: 12;
  width: 60px;
  height: 60px;
  opacity: $opacity--hidden;
  fill: $white;
  padding: calc($padding / 2);
  transition: $animation;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);

  &:hover {
    box-shadow: $shadow-edges;
    background-color: $grey--hover;
  }
}

.faded {
  opacity: $opacity--light;
}

.sectionTitle {
  display: block;
  margin: 0 0 calc($padding / 2);
  padding: calc($padding / 2) $padding;
  box-shadow: $shadow-edges;
  border-radius: $border-radius;
  background-color: $grey;
  text-transform: lowercase;

  span {
    font-weight: $font-weight--normal
  }
}

.preview {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overflow: hidden;
  overflow-y: scroll;
  backdrop-filter: blur(25px);
  background-color: rgba(0, 0, 0, 0.666);

  @include breakpoint('min-width', $breakpoint-medium) {
    @include border-radius($border-radius);
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-corner,
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-corner {
    display: none;
    width: 0;
    height: 0;
  }

  &Inner {
    position: relative;
    // height: 100%;
    width: 100%;
    max-width: 44rem;
    margin: auto;
    padding: $padding;
    color: $white;
    font-size: 1.05rem;

    @include breakpoint('min-width', $breakpoint-medium) {
      padding: calc($padding * 2);
    }

    img {
      max-width: 100%;
      border-radius: $border-radius;
      font-size: 0;
      @include box-shadow($shadow-edges);
    }

    img,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    ol,
    li,
    blockquote,
    pre,
    table {
      margin-bottom: $padding;
      text-decoration: none;
    }

    u {
      text-decoration: none;
    }

    h1,
    h2,
    h3,
    h4,
    p:first-of-type {
      text-wrap: balance;
    }

    ul,
    ol,
    li,
    blockquote,
    p {
      line-height: 1.6;
    }

    ul {
      padding-left: $padding;
    }


    figure {
      width: 100%;
      margin: 0;

      img,
      iframe {
        opacity: 1;
        margin: 0;
        width: 100%;
        filter: saturate(1);
        @include border-radius($border-radius);
        @include box-shadow($shadow-edges);
      }

      a {
        box-shadow: none;

        &:hover,
        &:focus {
          box-shadow: none;
          background: none;
        }
      }
    }

    h1 {
      font-size: 2.25rem;
      // text-wrap: balance;
      line-height: 1.1;
      margin-top: 0;
      margin-bottom: $padding;
    }

    h2 {
      margin-top: calc($padding * 2);
    }

    svg {
      display: none;
    }

    figcaption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: calc($padding / 2);
      font-size: 0.75rem;
      opacity: $opacity--half;
    }

    iframe {
      min-width: 100%;
      border: none;
      aspect-ratio: $sixteen-nine;
      @include box-shadow($shadow-edges);
      @include border-radius($border-radius);
    }

    button {
      background-color: $grey;
      padding: $padding;
      border: none;
      color: white;
      border-radius: $border-radius;
      transition: $animation;
      display: none;
    }

    a {
      color: $white;
      text-decoration: none;
      transition: $animation;
      font-weight: $font-weight--bold;
      box-shadow: 0 2px 0 0 $white;

      &:hover,
      &:focus {
        color: $black;
        background-color: white;
        box-shadow: 0 0 0 4px $white;
      }
    }

    p.article-copyright {
      display: none;
    }

    input {
      display: none;
    }

    code {
      margin-top: $padding;
      margin-bottom: $padding;
      background-color: $grey;
      padding: $padding;
      border-radius: calc($border-radius / 2);
      display: inline-block;
      width: 100%;
      overflow: scroll;
      scrollbar-width: none;

      p,
      ol,
      ul,
      li {
        margin: 0;
      }
    }
  }

  &:hover,
  &:focus {
    .close {
      opacity: $opacity--shown;
    }
  }
}