@import '../../styles/globals.scss';
@import '../../styles/mixins.scss';

.container {
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // padding: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  z-index: 1;
  object-fit: contain;
  outline: none;
  overflow: hidden;

  * {
    outline: none;
  }
}

.outer {
  position: relative;
  // height: 100%;
}

.pin {
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  // line-height: 42px;
  width: 150px;
  font-size: 1rem;
  font-weight: $font-weight--bold;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  svg {
    min-height: 42px;
    width: 42px;
    margin-bottom: 5px;
    fill: $black;
  }

  &Inner {
    line-height: 1;
    letter-spacing: -0.5px;
    text-align: center;
    background-color: $black;
    font-family: $font-family--titles;
    font-weight: $font-weight--normal;
    text-transform: capitalize;
    color: $white;
    width: auto;
    padding: $span-padding;
    outline: none;

    iframe {
      outline: none;
    }

    // div {
      // @include line-clamp(3);
      // overflow: hidden;
    // }
  }
}