@use "sass:math";

@import url('https://fonts.googleapis.com/css2?family=Hedvig+Letters+Serif:opsz@12..24&family=Pirata+One&display=swap');

$foundation: 1rem;
$padding: calc($foundation * 1.25);
$large-padding: 10vh;
$span-padding: 0.33rem 0.5rem;
$title-padding: calc($padding / 6);

$max-width: 96rem;
$max-height: 582.1px;
$max-height--header: 46.6px;
$border-radius: $foundation;
$nav-height: calc($foundation * 5.5);
$nav-height--mobile: calc($foundation * 4.5);

$font-family: "Hedvig Letters Serif", "Palatino Linotype", Palatino, Palladio, "URW Palladio L", "Book Antiqua", Baskerville, "Bookman Old Style", "Bitstream Charter", "Nimbus Roman No9 L", Garamond, "Apple Garamond", "ITC Garamond Narrow", "New Century Schoolbook", "Century Schoolbook", "Century Schoolbook L", Georgia, serif;

$font-family--titles: "Pirata One", $font-family;

$font-weight--light: 200;
$font-weight--normal: 300;
$font-weight--medium: 500;
$font-weight--bold: 600;
$font-size--body: 18px;

$line-height--title: 1;
$line-height--small: 1.15;

$opacity--shown: 1;
$opacity--semi: 0.85;
$opacity--half: 0.5;
$opacity--light: 0.33;
$opacity--quarter: 0.25;
$opacity--hidden: 0;

$opacity-start: 0.5;
$opacity-end: 1;

$white: #fff;
$black: #3f3b38;
$black--hover: #1f1f1b;
$black--hover-alt: rgba($black, 0.125);
$grey: rgba(255, 255, 255, 0.075);
$grey--hover: rgba(255, 255, 255, 0.33);
$red: #db4c69;
$red--hover: rgba($red, 0.175);
$blue: #3e97e4;
$blue--hover: rgba($blue, 0.1);
$green: #89df93;
$yellow: #ecce7b;

$biege: #a79989;

$border-color : lighten($biege, 10%);
$border-width: 2px;

$shadow: 0.5rem 0 2rem -0.5rem rgba(0, 0, 0, 0.33);
$shadow-edges--shadow-only: 0 0 40px -5px rgba(0, 0, 0, 0.125);
$shadow-edges: inset 0 0 1px 0 rgba(255, 255, 255, 0.15), 0 0 40px -5px rgba(0, 0, 0, 0.125);
$shadow-edges--red: inset 0 0 1px rgba($red, 0.333), 0 0 40px -5px rgba($red, 0.125);
$shadow-edges--blue: inset 0 0 1px rgba($blue, 0.333), 0 0 40px -5px rgba($blue, 0.125);
$shadow-edges--hover: inset 0 0 1px rgba(255, 255, 255, 0.3), 0 0 40px -5px rgba(0, 0, 0, 0.125);

$shortcut-feeds: 4;

$sixteen-nine: math.div(16, 9);
$one-one: math.div(1, 1);
$four-three: math.div(4, 3);
$steam-aspect: math.div(16, 7.5);
$trakt-aspect: math.div(6, 9);

$breakpoint-small: 480px;
$breakpoint-medium: 768px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1280px;
$breakpoint-xxlarge: 1440px;
$breakpoint-xxxlarge: 1800px;
$breakpoint-xxxxlarge: 2400px;

// $cursor: url('icons/icon__cursor.png'), auto !important;

$animation: opacity 0.3s ease-in-out,
  width 0.3s ease-in-out,
  color 0.3s ease-in-out,
  background-color 0.3s ease-in-out,
  transform 0.3s ease-in-out,
  border-radius 0.3s ease-in-out,
  filter 0.3s ease-in-out,
  box-shadow 0.3s ease-in-out,
  padding 0.3s ease-in-out,
  height 0.3s ease-in-out;

$animation-card: box-shadow 0.3s ease-in-out,
  background-color 0.3s ease-in-out;

$animation-long: opacity 1.3s ease-in-out,
  color 1.3s ease-in-out,
  background-color 1.3s ease-in-out,
  filter 1.3s ease-in-out,
  transform 1.3s ease-in-out,
  border-radius 1.3s ease-in-out,
  padding 1.3s ease-in-out,
  height 1.3s ease-in-out;
