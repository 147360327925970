@mixin breakpoint($param: 'min-width', $size) {
  @media (#{$param}: $size) {
    @content;
  }
}

// Truncate (Line Clamp)
@mixin line-clamp($lines: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Pseudo Content
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

// Border Radius
@mixin border-radius($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

// Hide from Screen-readers
@mixin hidden {
  display: none;
  visibility: hidden;
}

// Generic Transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// Transition
@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

// Box Shadow
@mixin box-shadow($params) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

// Center
@mixin center($horizontal: true, $vertical: true) {
  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

// Unselectable
@mixin unselectable {
  -webkit-touch-callout: none;
  user-select: none;
}

// Highlights
@mixin selection {
  ::-moz-selection {
    @content;
  }

  ::selection {
    @content;
  }
}